
.footer {
   padding-top: 2rem;
}

main{
  width: 100%;
  padding: 20px;
}
.sidebar{
  color: rgba(0, 0, 0, 0.55);
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link{
  display: flex;
  color: rgba(0, 0, 0, 0.55);
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover{
  background:lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
  font-size: 20px;
}

@media (max-width: 575.98px) {
  .fontsize{
    font-size: 1,5rem;
  }
  .fontsize2{
    font-size: 1rem;
  }
  .footer{
    padding-top: 0;
  }
  
}
@media (max-width: 432px) {
  .fontsize{
    font-size: 1rem;
  }
  .fontsize2{
    font-size: 0,75rem;
  }
  .footer{
    padding-top: 0;
  }
  
}
@media (min-width: 1400px) {
 
  
  .footer{
    padding-top: 5rem;
  }
  
}


.product {
  display: flex;
}

.product--image {
  order: 1;
}

.product--detail {
  order: 2;
  @media only screen and (min-width: 600px) {
     order: 0;
  }
}
